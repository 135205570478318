<template>
  <div>
    <b-form class="mb-2">
      <b-row>
        <b-col md="12">
          <h2>Identitas Unit Pengelola Program Studi</h2>
        </b-col>
        <b-col v-for="field in identityFields" :key="field.id" md="6">
          <b-form-group>
            <label :for="field.name">{{field.label}}</label>
            <b-form-input :type="field.type" v-model="identity[field.name]" required :id="field.name" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-form @submit="handleTeamSubmit">
      <b-row>
        <b-col md="12">
          <h2>{{ isEdit ? 'Ubah' : 'Tambah' }} Identitas Pengisi Laporan Evaluasi Diri</h2>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="nama_instansi">Nama</label>
            <b-form-input v-model="team.name" required id="nama_instansi" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="bulan_tanggal">NIDN</label>
            <b-form-input type="number" v-model="team.nidn" required id="bulan_tanggal" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="ketua_majelis_akreditasi">Jabatan</label>
            <b-form-input v-model="team.position" required id="ketua_majelis_akreditasi" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="ketua_majelis_akreditasi">Tanggal Pengisian</label>
            <b-form-input v-model="team.fill_time" required id="ketua_majelis_akreditasi" />
          </b-form-group>
        </b-col>
        <b-col md="12" class="mt-2 d-flex align-items-center justify-content-end">
          <b-button v-if="isEdit" type="submit" variant="secaondary" class="mr-1" @click="handleEditCancel">Batal</b-button>
          <b-button type="submit" variant="primary" :disabled="isLoading">{{ isEdit ? 'Ubah' : 'Tambah' }}</b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-row class="mt-2">
      <b-col v-for="team in teams" :key="team.id" md="6">
        <b-card 
          border-variant="primary"
        >
          <b-card-header class="p-0 pl-1 pb-1 d-flex align-items-center justify-content-between">
            <h4>{{ team.name }}</h4>
            <div>
              <b-button class="btn-icon mr-1" variant="warning" @click="handleEdit(team)">
                <feather-icon
                  icon="Edit3Icon"
                  size="16"
                />
              </b-button>
              <b-button class="btn-icon mr-1"variant="danger" @click="handleDelete(team)">
                <feather-icon
                  icon="DeleteIcon"
                  size="16"
                />
              </b-button>
            </div>
          </b-card-header>
          <b-list-group flush>
            <b-list-group-item href="#"><b>NIDN :</b> {{team.nidn}}</b-list-group-item>
            <b-list-group-item href="#"><b>Posisi : </b> {{team.position}}</b-list-group-item>
            <b-list-group-item href="#"><b>Waktu Pengisian : </b> {{team.fill_time}}</b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";
import { confirm } from "@/libs/utils";
import useJwt from '@/auth/jwt/useJwt'
export default {
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    const identityFields = [
      { id: uuid(), name: 'program_studi', label: 'Program Studi', type: 'text' },
      { id: uuid(), name: 'jurusan', label: 'Jurusan', type: 'text' },
      { id: uuid(), name: 'fakultas', label: 'Fakultas', type: 'text' },
      { id: uuid(), name: 'perguruan_tinggi', label: 'Perguruan Tinggi', type: 'text' },
      { id: uuid(), name: 'no_sk_pendirian_ps', label: 'Nomor SK pendirian PS (*) ', type: 'text' },
      { id: uuid(), name: 'tanggal_sk_pendirian_ps', label: 'Tanggal SK Pendirian PS', type: 'text' },
      { id: uuid(), name: 'penyelengraaan_ps', label: 'Bulan & Tahun Dimulainya Penyelenggaraan PS ', type: 'text' },
      { id: uuid(), name: 'no_sk_izin_operasional', label: 'Nomor SK Izin Operasional (*)', type: 'text' },
      { id: uuid(), name: 'tanggal_sk_izin_operasional', label: 'Tanggal SK Izin Operasional', type: 'text' },
      { id: uuid(), name: 'peringkat_akreditasi_terakhir', label: 'Peringkat Akreditasi Terakhir', type: 'text' },
      { id: uuid(), name: 'no_sk_ban_pt', label: 'Nomor SK BAN-PT', type: 'text' },
      { id: uuid(), name: 'alamat_ps', label: 'Alamat PS', type: 'text' },
      { id: uuid(), name: 'no_telepon_ps', label: 'No. Telepon PS', type: 'number' },
      { id: uuid(), name: 'email_ps', label: 'Homepage dan E-mail PS', type: 'text' },
      { id: uuid(), name: 'no_ketua_tim', label: 'Nomor WA Ketua Tim', type: 'number' },
      { id: uuid(), name: 'email_ketua_tim', label: 'Alamat E-Mail Ketua Tim', type: 'text' },
    ];
    return {
      title: 'Lampiran',
      isLoading: false,
      isEdit: false,
      teams: this.form.data,
      team: {
        nidn: '',
        fill_time: '',
        position: '',
        name: ''
      },
      identityFields,
      identity: this.form.content ? JSON.parse(this.form.content) : identityFields.reduce((acc, cur) => ({ ...acc, [cur.name]: '-' }), {}),
    }
  },
  methods: {
    async submit() {
      const data = {
        ...this.form,
        data: this.teams,
        content: JSON.stringify(this.identity),
        led_id: this.$route.params.id
      }
      const response = await useJwt.http.put(`led_contents/${this.form.id}`, data);
      this.$emit('submit', this.form)
      return true;
    },
    async handleTeamSubmit(e) {
      e.preventDefault();
      this.isLoading = true;
      if(this.isEdit) {
        const response = await useJwt.http.put(`led_teams/${this.team.id}`, this.team);
        this.teams = response.data.led_teams;
      } else {
        const data = { ...this.team, list_led_id: this.$route.params.id };
        const response = await useJwt.http.post(`led_teams`, data);
        this.teams = [...this.teams, response.data.led_teams];
      }
      this.isLoading = false;
    },
    handleEdit(team) {
      this.isEdit = true;
      this.team = { ...team };
    },
    handleDelete(team) {
      confirm("Data akan dihapus?").then(async() => {
        const response = await useJwt.http.delete(`led_teams/${team.id}/1?list_led_id=${team.list_led_id}`, team);
        this.teams = response.data.led_teams;
      })
    },
    handleEditCancel() {
      this.team = {};
      this.isEdit = false;
    }
  }
}
</script>