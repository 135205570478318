<template>
  <div>
    <h2>{{form.title}}</h2>
    <editor
      :data="form.data"
      @input="handleEditorChange"
      @wordCount="handleEditorWordCountChange"
    />
    <b-row class="mt-2" gutter>
      <b-col cols="12">
        <b-row gutter>
          <b-col cols="2">
            <h4>Jumlah Kata</h4>
          </b-col>
          <b-col cols="10">
            <h4 v-if="template.word_limits === 0"><b>{{ total_words }}</b> Kata</h4>
            <b-progress v-else :max="100" height="2rem">
              <b-progress-bar :style="{ 'background-color': `${total_words > template.word_limits ? '#f9cfcf' : '#009B4C'}` }" :value="total_words / template.word_limits * 100 >= 10.5 ? total_words / template.word_limits * 100 : 10.5">
                <span><strong>{{total_words}} / {{template.word_limits}}</strong></span>
              </b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-if="template.has_children || template.parent_id" cols="12" class="mt-1">
        <b-row gutter>
          <b-col cols="12" class="d-flex align-items-center justify-content-between">
            <h4>Uraian Halaman</h4>
            <b-button variant="primary" size="sm" @click="handleGetForm">Lihat Uraian</b-button>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-collapse v-model="collapseUraian" id="collapse-one" class="mt-2">
              <b-table-simple hover small caption-top responsive>
                <caption>Ini adalah uraian total halaman dari bab atau sub-bab lain yang berkaitan:</caption>
                <colgroup><col><col></colgroup>
                <colgroup><col><col><col></colgroup>
                <colgroup><col><col></colgroup>
                <b-thead head-variant="secondary">
                  <b-tr>
                    <b-th>Nama</b-th>
                    <b-th>Jumlah Halaman</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="child in template_children" :key="child.id">
                    <b-td>{{ child.title }}</b-td>
                    <b-td>{{ child.total_pages }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-td><b>Total</b></b-td>
                    <b-td><b>{{ template_children.map(child => child.total_pages).reduce((a, b) => a + b, 0) }}</b></b-td>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-collapse>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-if="template.rules.length > 0" cols="12" class="mt-1">
        <b-row gutter>
          <b-col cols="12">
            <h4>Deskripsi</h4>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-list-group>
              <b-list-group-item v-for="(rule, index) in template.rules" :key="index">{{ rule }}</b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" class="mt-1">
        <b-row gutter>
          <b-col cols="2">
            <h4>Jumlah Halaman</h4>
          </b-col>
          <b-col cols="10">
            <h4><b>{{ form.total_pages }}</b> Halaman</h4>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Editor from '@/views/components/Editor';
import useJwt from '@/auth/jwt/useJwt'
import FORMS from './forms.js';
export default {
  components: {
    Editor
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      data: this.form.data,
      total_words: this.form.total_words,
      forms: [],
      template_children: [],
      collapseUraian: false,
    };
  },
  computed: {
    template() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.collapseUraian = false
      console.log(`my from.template_id : ${this.form.template_id}`);
      return FORMS.find(f => f.id === this.form.template_id);
    },
  },
  methods: {
    async backStep() {
      // default || collapse uraian
      console.log(`on backStep`)
      this.collapseUraian = false
      return true
    },
    async submit() {
      console.log(`on submit`)
      const data = {
        ...this.form,
        content: this.data,
        led_id: this.$route.params.id,
        total_words: this.total_words
      }
      const { data: resp } = await useJwt.http.put(`led_contents/${this.form.id}`, data);
      const form = {
        ...this.form,
        ...resp.data,
        total_words: this.total_words
      }
      this.$emit('submit', {...this.form, ...resp.data})
      return true;
    },
    handleEditorChange(val) {
      this.data = val;
    },
    handleEditorWordCountChange(stats) {
      this.total_words = stats.words;
    },
    handleGetForm() {
      this.collapseUraian = !this.collapseUraian
      this.forms = this.$parent.$parent.$parent.getCurrentMyData();
      let children = []
      console.log(`current template : ${JSON.stringify(this.template)}`);
      if(this.template.has_children || this.template.parent_id) {
        console.log(`children first if`);
        if(this.template.has_children) {
          console.log(`children if`);
          children = this.forms.filter(form => form.template_parent_id == this.template.id);
        } else {
          console.log(`children else`);
          console.log(`this form : ${this.forms}`);

          const siblings = this.forms.filter(form => form.template_parent_id === this.template.parent_id);
          console.log(`children siblings : ${siblings}`);
          console.log(`has filter by this.template.parent_id ${this.template.parent_id}`);

          const parent = this.forms.find(form => form.template_id === this.template.parent_id);
          console.log(`children parent ${parent}`);
          console.log(`has filter by ${this.template.parent_id}`);
          children = [parent, ...siblings]
        }
      }
      // console.log(`children ${children}`);
      this.template_children = children;
    }
  }
}
</script>