<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h2 class="m-0">{{form.title}}</h2>
      <!-- <b-button @click="handleListAdd" variant="primary">Tambah Daftar Isi</b-button> -->
    </div>
    <ListItem 
      v-for="item in watchedList"
      :key="item.id"
      :item="item"
      @delete="handleItemDelete(item)"
      @edit="handleItemEdit"
      @add="handleItemAdd(item)"
    >
      <template
        v-if="item.childs.length > 0"
      >
        <ListItem 
          v-for="child in item.childs"
          :key="child.id"
          :item="child"
          :without-add="true"
          @delete="handleItemDelete(child)"
          @edit="handleItemEdit"
        />
      </template>
    </ListItem>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import useJwt from '@/auth/jwt/useJwt'
import ListItem from './ListItem';
export default {
  components: {
    ListItem
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      list: []
    };
  },
  created() {
    this.list = this.form.data;
  },
  computed: {
    watchedList() {
      return this.list;
    }
  },
  methods: {
    handleItemDelete(selected) {
      if('childs' in selected) {
        // IF PARENT
        this.list = this.list.filter(item => item.id !== selected.id)
      } else {
        // IF CHILD
        const parent = this.list.filter(item => {
          const hasChildren = item.childs.length > 0;
          return hasChildren && item.childs.filter(child => child.id === selected.id).length > 0;
        })[0]
        if(parent) {
          const parentIndex = this.list.findIndex(item => item.id === parent.id);
          const childs = parent.childs.filter(child => child.id !== selected.id);
          this.list[parentIndex].childs = childs;
        }
      }
    },
    handleItemEdit(id, data) {
      if('childs' in data) {
        // IF PARENT
        const itemIndex = this.list.findIndex(item => item.id === id);
        if(this.list[itemIndex]) {
          const list = this.list;
          list[itemIndex] = {...this.list[itemIndex], ...data};
          this.list = [...list];
        }
      } else {
        // IF CHILD
        const parent = this.list.filter(item => {
          const hasChildren = item.childs.length > 0;
          return hasChildren && item.childs.filter(child => child.id === id).length > 0;
        })[0]
        if(parent) {
          const parentIndex = this.list.findIndex(item => item.id === parent.id);
          const childIndex = parent.childs.findIndex(child => child.id === id);
          const childs = parent.childs;
          childs[childIndex] = {
            ...childs[childIndex],
            ...data
          }
          const list = this.list;
          this.list[parentIndex].childs = childs;
          this.list = [...list];
        }
      }
      
    },
    handleItemAdd(selected) {
      const index = this.list.findIndex(item => item.id === selected.id);
      const item = this.list[index];
      if(item) {
        const page = item.childs.length <= 0 ? item.page + 1 : item.childs[item.childs.length - 1].page + 1;
        const child = {
          id: uuid(),
          name: 'Sub Bagian Baru',
          page,
        }
        this.list[index].childs = [...item.childs, child]
      }
    },
    handleListAdd() {
      const lastItem = this.list[this.list.length - 1];
      const lastItemChild = lastItem && lastItem.childs.length > 0 ? lastItem.childs[lastItem.childs.length - 1] : lastItem;
      const page = lastItemChild ? lastItemChild.page + 1 : 1;
      this.list = [
        ...this.list,
        {
          id: uuid(),
          name: 'Bagian Baru',
          page,
          childs: []
        }
      ]
    },
    async submit() {
      const table_of_contents = [...this.list, ...this.list.flatMap(list => list.childs)].sort((a, b) => a.id - b.id);
      const data = {
        ...this.form,
        table_of_contents,
        data: this.list,
      }
      const response = await useJwt.http.put(`led_table_of_contents/${this.$route.params.id}`, data);
      emit('submit', this.form)
      return true;
    },
  }
}
</script>