<template>
  <div>
    <b-row>
      <b-col
        cols="8"
        class="d-flex align-items-center justify-content-start"
      >
        <h4>Konfirmasi Penyelesaian</h4>
      </b-col>
    </b-row>
    <b-table-simple
      hover
      small
      caption-top
      responsive
    >
      <caption>Ini adalah uraian total halaman dari bab atau sub-bab lain yang ada:</caption>
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead head-variant="secondary">
        <b-tr>
          <b-th>Nama</b-th>
          <b-th>Jumlah Halaman</b-th>
          <b-th>Jumlah Kata</b-th>
          <b-th>Batas Halaman</b-th>
          <b-th>Batas Kata</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="form in forms.filter(form => form.type !== 'finish')"
          :key="form.id"
          :variant="getRowVariant(form)"
        >
          <b-td>{{ form.title }}</b-td>
          <b-td>{{ form.total_pages }}</b-td>
          <b-td>{{ form.total_words === 0 ? '-' : form.total_words }}</b-td>
          <b-td>{{ getFormPageLimits(form.template_id) === 0 ? '-' : getFormPageLimits(form.template_id) }}</b-td>
          <b-td>{{ getFormWordLimits(form.template_id) === 0 ? '-' : getFormWordLimits(form.template_id) }}</b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td><b>Total</b></b-td>
          <b-td><b>{{ forms.map(form => form.total_pages).filter(pages => pages != undefined).reduce((a, b) => a + b, 0) }}</b></b-td>
          <b-td><b>{{ forms.map(form => form.total_words).filter(words => words != undefined).reduce((a, b) => a + b, 0) }}</b></b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
    <b-alert
      v-if="isPass"
      show
      variant="success"
      class="p-1 mt-3"
    >
      <b-row>
        <b-col
          cols="8"
          class="d-flex align-items-center justify-content-start"
        >
          <p>Ketika menekan tombol "Selesai", maka file akan digabungkan yang kemudian bisa didownload dengan menekan tombol di bawah</p>
        </b-col>
        <b-col
          cols="4"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="primary"
            class="btn-icon mt-1"
            :disabled="isDownloadDisabled"
            @click="handleDownload"
          >
            <feather-icon
              icon="DownloadCloudIcon"
              size="16"
            />
            Download File
          </b-button>
        </b-col>
      </b-row>
    </b-alert>
    <b-alert
      v-else
      show
      variant="danger"
      class="p-1 mt-3"
    >
      <p>Tolong perhatikan kembali total halaman dan kata per bab apakah sudah sesuai dengan <b>batas</b> dari peraturan yang ada</p>
    </b-alert>
  </div>
</template>

<script>
import FORMS from './forms'

export default {
  // props: {
  //   forms: {
  //     type: Array,
  //     default: () => ({})
  //   }
  // },
  data() {
    return {
      isDownloadDisabled: true,
      filename: null,
      status: 'not-confirmed',
      isFinishDisabled: false,
      forms: [],
    }
  },
  // created() {
  //   console.log(`finish on creatd`)
  //   this.forms = this.$parent.$parent.$parent.getCurrentMyData()
  // },
  computed: {
    isPass() {
      return this.autoCheck()
    },
  },
  methods: {
    async autoCheck() {
      const forms = this.$parent.$parent.$parent.getCurrentMyData().filter(form => form.template_id)
      this.forms = forms
      console.log('run isPass()')
      if (forms.length > 0) {
        const forms_templates = forms.map(form => {
          const template = FORMS.find(temp => temp.id === form.template_id)
          return { ...form, template }
        })
        const with_rules = forms_templates.filter(form => form.template.word_limits !== 0 || form.template.page_limits !== 0)

        const is_pass = with_rules.map(form => {
          if (form.template.word_limits !== 0) {
            return form.total_words <= form.template.word_limits
          }
          if (form.template.page_limits !== 0) {
            return form.total_pages <= form.template.page_limits
          }
          return false
        })
        console.log(`return ${!is_pass.includes(false)}`)
        this.isFinishDisabled = !is_pass.includes(false)
        return !is_pass.includes(false)
      }
      console.log('return default false')
      this.isFinishDisabled = false
      return false
    },
    validatorParent() {
      return this.isFinishDisabled
    },
    getRowVariant(form) {
      if (form.template_id) {
        const template = FORMS.find(temp => temp.id === form.template_id)
        if (template.word_limits !== 0) {
          return form.total_words <= template.word_limits ? '' : 'danger'
        }
        if (template.page_limits !== 0) {
          return form.total_pages <= template.page_limits ? '' : 'danger'
        }
      }
      return ''
    },
    getFormWordLimits(template_id) {
      return template_id ? FORMS.find(form => form.id === template_id).word_limits : 0
    },
    getFormPageLimits(template_id) {
      return template_id ? FORMS.find(form => form.id === template_id).page_limits : 0
    },
    handleDownload() {
      window.open(`${process.env.VUE_APP_BACKEND_URL}/${this.filename}`, '_blank')
    },
    unReadyForDownload() {
      this.isDownloadDisabled = true
    },
    readyForDownload(filename) {
      this.filename = filename
      this.isDownloadDisabled = false
    },
  },
}
</script>

<style>

</style>
