<template>
  <div>
    <h2>Kata Pengantar</h2>
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <b-row>
        <b-col class="mb-2" cols="12">
          <editor
            :data="form.data"
            @input="handleEditorChange"
            @wordCount="handleEditoWordCountChange"
          />
        </b-col>
        <b-col cols="12">
          <b-row class="justify-content-between">
            <b-col cols="6">
                <b-row class="mt-2" gutter>
                <b-col cols="12">
                  <b-row gutter>
                    <b-col cols="2">
                      <h4>Jumlah Kata</h4>
                    </b-col>
                    <b-col cols="10">
                      <h4><b>{{ total_words }}</b> Kata</h4>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col cols="12" class="mt-1">
                  <b-row gutter>
                    <b-col cols="2">
                      <h4>Jumlah Halaman</h4>
                    </b-col>
                    <b-col cols="10">
                      <h4><b>{{ form.total_pages }}</b> Halaman</h4>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <b-row class="flex-column">
                    <b-col>
                      <b-input
                        class="mb-1"
                        v-model="signaturePlaceAndDate"
                        placeholder="Jakarta, 23 Agustus 2021"
                      />
                    </b-col>
                    <b-col>
                      <b-input
                        class="mb-1"
                        v-model="signaturePosition"
                        placeholder="Ketua Umum"
                      />
                    </b-col>
                    <b-col>
                      <b-input
                        v-model="signatureName"
                        placeholder="Prof. Dr. Muchias Samani"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="6">
                  <v-dropzone
                    id="files"
                    ref="files"
                    :options="{
                      url: BACKEND_URL,
                      maxFilesize: 5,
                      autoProcessQueue: false,
                      acceptedFiles: '.jpeg,.jpg,.png',
                    }"
                    use-custom-slot
                    add-remove-links
                    @vdropzone-file-added="addFile"
                  >
                    <h5 class="mb-0">
                      Masukkan gambar tanda tangan
                    </h5>
                  </v-dropzone>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-skeleton-wrapper>
    <!-- <b-row class="mt-2" gutter>
      <b-col cols="12">
        <b-row gutter>
          <b-col cols="2">
            <h4>Jumlah Kata</h4>
          </b-col>
          <b-col cols="10">
            <h4><b>{{ total_words }}</b> Kata</h4>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" class="mt-1">
        <b-row gutter>
          <b-col cols="2">
            <h4>Jumlah Halaman</h4>
          </b-col>
          <b-col cols="10">
            <h4><b>{{ form.total_pages }}</b> Halaman</h4>
          </b-col>
        </b-row>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import Editor from '@/views/components/Editor'

import VDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import useJwt from '@/auth/jwt/useJwt'
export default {
  components: {
    Editor,
    VDropzone,
  },
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  created() {
    const {
      signaturePlaceAndDate = '',
      signaturePosition = '',
      signatureName = ''
    } = JSON.parse(this.form.additional_content || '{}')
    this.signaturePlaceAndDate = signaturePlaceAndDate
    this.signaturePosition = signaturePosition
    this.signatureName = signatureName
  },
  data() {
    return {
      isLoading: false,
      data: this.form.data,
      signaturePlaceAndDate: '',
      signaturePosition: '',
      signatureName: '',
      total_words: this.form.total_words,
      BACKEND_URL: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    async submit() {
      const data = {
        ...this.form,
        content: this.data,
        signature: this.signature,
        signaturePlaceAndDate: this.signaturePlaceAndDate,
        signaturePosition: this.signaturePosition,
        signatureName: this.signatureName,
        led_id: this.$route.params.id,
        total_words: this.total_words
      }

      const formData = new FormData
      Object.keys(data).forEach(key => formData.append(key, data[key]))

      const { data: resp } = await useJwt.http.put(`led_contents/${this.form.id}`, formData);
      const form = {
        ...this.form, 
        ...resp.data,
        total_words: this.total_words
      }
      this.$emit('submit', {...this.form, ...resp.data})
      return true;
    },
    handleEditorChange(val) {
      this.data = val;
    },
    handleEditoWordCountChange(stats) {
      this.total_words = stats.words;
    },
    addFile(file) {
      this.signature = file
    },
  }
}
</script>