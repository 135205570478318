<template>
  <div>
    <b-button
      class="mb-2"
      variant="outline-secondary"
      @click="backButton()"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        :style="{ cursor: 'pointer' }"
      />
      <span>Kembali</span>
    </b-button>

    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </b-card>
      </template>

      <b-card id="led-form">
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-between align-items-center"
          >
            <b-dropdown
              id="status"
              :variant="statusVariant(led.status)"
              :disabled="isFinishDisabled"
            >
              <template #button-content>
                <span v-text="led.status || 'Tidak ada status'" />
              </template>
              <b-dropdown-header id="dropdown-header-label">
                <small class="text-secondary">Pilih status</small>
              </b-dropdown-header>
              <b-dropdown-item
                variant="info"
                @click.prevent="onUpdateStatus('led-disable')"
              >
                {{ led.has_reviewed ? 'Dapat Ditinjau Ulang' : 'Dapat Ditinjau' }}
              </b-dropdown-item>
              <b-dropdown-item
                variant="warning"
                @click.prevent="onUpdateStatus('Proses Pengisian', 'led-disable')"
              >
                Proses Pengisian
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="onUpdateStatus('', 'led-disable')">
                Tidak ada status
              </b-dropdown-item>
            </b-dropdown>
            <div
              class="form-dropdown"
              :style="{ position: 'relative' }"
            >
              <b-button
                variant="outline-primary"
                class="form-dropdown-button"
                @click="toggleFormDropdown"
              >
                <span class="mr-1">{{ currentTabTitle }}</span>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="15"
                  class="text-primary"
                />
              </b-button>
              <b-card
                v-show="formDropdownVisible"
                class="form-dropdown-card"
                body-class="p-0 d-flex flex-column"
                border-variant="secondary"
              >
                <b-card-header header-class="p-1">
                  <b-form-input
                    v-model="wizardListKeyword"
                    placeholder="Cari"
                  />
                </b-card-header>
                <b-list-group
                  class="flex-grow-1"
                  :style="{ borderRadius: 0, overflowY: 'scroll' }"
                >
                  <b-list-group-item
                    v-for="(form, index) in wizardList"
                    :key="`${index}`"
                    class="form-list border-0"
                    :disabled="wizardList[index - 1] && !wizardList[index - 1].is_finish"
                    @click="index != selectedTab ? changeTab(index) : null"
                  >
                    <span
                      :class="`text-${index == selectedTab ? 'primary font-weight-bold' : 'dark'}`"
                      style="max-width: 90%"
                    >
                      {{ form.title }}
                    </span>
                    <feather-icon
                      v-if="form.is_finish"
                      icon="CheckIcon"
                      class="text-primary"
                    />
                    <!-- <feather-icon
                      class="text-primary"
                      icon="CheckIcon"
                      size="20"
                    /> -->
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </div>
          </b-col>
          <b-col cols="12">
            <form-wizard
              ref="formWizard"
              color="#7367F0"
              :title="null"
              :subtitle="null"
              :start-index="startIndex"
              shape="tab"
              finish-button-text="Selesai"
              back-button-text="Kembali"
              next-button-text="Lanjut"
              next-button
              class="mb-3"
              step-size="xs"
              @on-complete="formSubmitted"
              @on-change="onTabChange"
            >
              <tab-content
                v-for="(form, index) in forms"
                :key="`${index}`"
                class="mb-3"
                :title="form.title"
              >
                <component
                  :is="form.type"
                  :ref="`${form.type}-${form.id}`"
                  :form="form"
                  :forms="forms"
                  @submit="handleComponentFinish"
                />
              </tab-content>
              <template #finish>
                <b-button
                  :style="{ backgroundColor: 'rgb(115, 103, 240) !important', borderColor: 'rgb(115, 103, 240) !important', color: 'white', }"
                  :disabled="isFinishDisabled"
                >
                  Selesai
                </b-button>
              </template>
            </form-wizard>
          </b-col>
        </b-row>
      </b-card>
    </b-skeleton-wrapper>
    <b-button
      id="led-btn-to-top"
      variant="primary"
      class="btn-icon"
      :class="{'show': y > 250}"
      @click="scrollToBottom"
    >
      <feather-icon
        icon="ArrowDownIcon"
        size="16"
      />
    </b-button>
    <template v-if="!isLoading">
      <!-- Grade -->
      <Grade
        gradable="LED"
        :ts="led.ts"
        :completed="led.status == 'Selesai'"
      />

      <!-- Comment -->
      <Comment
        ref="comment"
        commentable="LED"
        :commentable-id="led.id"
      />
    </template>
  </div>
</template>

<script>
import { useWindowScroll } from '@vueuse/core'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { camelize, capitalize } from '@libs/utils'
import { v4 as uuid } from 'uuid'

// COMPONENTS
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Wizards
import KataPengantar from './forms/KataPengantar.vue'
import Lampiran from './forms/Lampiran.vue'
import DaftarIsi from './forms/DaftarIsi/Index.vue'
import Finish from './forms/Finish'
import Bab from './forms/Bab'

// OTHERS
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'
import FORMS from './forms/forms'

import Comment from '../../components/operator/Comment.vue'
import Grade from '../../components/operator/Grade.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    vSelect,
    ToastificationContent,
    // Wizards
    KataPengantar,
    DaftarIsi,
    Lampiran,
    Finish,
    Bab,
    Comment,
    Grade,
  },
  setup() {
    const { y } = useWindowScroll()

    const scrollToBottom = () => {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 9999999999,
        behavior: 'smooth',
      })
    }

    return { y, scrollToBottom }
  },
  data() {
    return {
      formDropdownVisible: false,
      selectedTab: 0,
      wizardListKeyword: '',
      forms: [],
      led: {},
      isLoading: true,
      isFinishDisabled: true,
    }
  },
  computed: {
    statusVariant() { return any => this.$variantDropDown[any] },
    wizardList() {
      const keyword = this.wizardListKeyword.toLowerCase()
      if (keyword) {
        return this.forms.filter(form => form.title.replace(/-/g, ' ').toLowerCase().includes(keyword))
      }
      return this.forms
    },
    currentTabTitle() {
      const selectedForm = this.forms.find((_, index) => index == this.selectedTab)
      return selectedForm ? selectedForm.title : 'No Tab'
    },
    contentList() {
      return this.forms
        .filter(form => form.type.includes('bab'))
        .map(form => ({ ...form, label: form.title, value: form.id }))
    },
    startIndex() {
      if (this.forms.length > 0) {
        const formIndex = this.forms.findIndex(form => form.id == this.led.last_content)
        return formIndex < 0 ? 0 : formIndex
      }
      return 0
    },
  },
  created() {
    const hasSomeParentTheClass = (element, classname) => {
      if ('classList' in element) {
        if (element.classList.contains(classname)) return true
        return element.parentNode && hasSomeParentTheClass(element.parentNode, classname)
      }
    }

    window.addEventListener('click', event => {
      if (hasSomeParentTheClass(event.target, 'form-dropdown')) {
        if (!hasSomeParentTheClass(event.target, 'form-dropdown-button')) {
          this.formDropdownVisible = true
        }
      } else {
        this.formDropdownVisible = false
      }
    })
    const { id } = this.$route.params
    const { id: user_id } = getUserData()
    useJwt.http.get(`list_led/${id}`, {
      params: { user_id },
    })
      .then(response => {
        const { list_leds: led } = response.data
        this.led = led
        const forms = led.led_contents.map(led_content => {
          const {
            id, title, type, slug, content, additional_content, is_finish, total_pages, total_words,
          } = led_content
          const { user_id } = led
          const form = FORMS.find(form => form.slug === slug)
          const items = {
            id,
            title,
            type,
            slug,
            content,
            additional_content,
            is_finish,
            total_pages,
            total_words,
            template_id: form ? form.id : null,
            template_parent_id: form && form.parent_id ? form.parent_id : null,
          }
          if (led_content.type == 'daftar-isi') {
            const tables = this.formatTableOfContents(led.led_table_of_contents)
            return { ...items, data: tables }
          }
          if (led_content.type == 'lampiran') {
            return { ...items, content, data: led.led_teams }
          }
          const data = content || form.content || ''
          return { ...items, data }
        })
        this.forms = [...forms, {
          id: 0,
          type: 'finish',
          title: 'Konfirmasi Penyelesaian',
        }]
        this.selectedTab = this.forms.findIndex(form => form.id == this.led.last_content)

        // CHECK IF CAN SUBMIT
        const forms_templates = this.forms.filter(form => form.template_id).map(form => {
          const template = FORMS.find(temp => temp.id === form.template_id)
          return { ...form, template }
        })
        const with_rules = forms_templates.filter(form => form.template.word_limits !== 0 || form.template.page_limits !== 0)
        const is_pass = with_rules.map(form => {
          if (form.template.word_limits !== 0) {
            return form.total_words <= form.template.word_limits
          }
          if (form.template.page_limits !== 0) {
            return form.total_pages <= form.template.page_limits
          }
          return false
        })
        this.isFinishDisabled = is_pass.includes(false)
      })
      .catch(err => {
        console.log(err)
        this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' })
      })
      .finally(() => this.isLoading = false)
  },
  methods: {
    uuid,
    capitalize,
    camelize,
    backButton() {
      this.$router.push({ name: 'op-led' })
    },
    getCurrentMyData() {
      console.log(`get current template ${this.forms}`)
      return this.forms
    },
    formatTableOfContents(items) {
      const parent_items = items.filter(item => item.parent_id == null)
      const children_items = items.filter(item => item.parent_id)
      const tables = parent_items.map(parent => {
        const childs = children_items.filter(child => child.parent_id == parent.id)
        return { ...parent, childs }
      })

      return tables
    },
    // uuidv4,
    async formSubmitted() {
      const helper_unique = this.led.helper_unique.split('/').join('-')
      const pdfs = this.forms
        .filter(form => form.type != 'finish')
        .map(({ id, type }) => (type == 'bab' ? `${helper_unique}-${type}-${id}.pdf` : `${helper_unique}-${type}.pdf`))

      const data = { helper_unique, pdfs, led_id: this.led.id }
      const { data: merged } = await useJwt.http.post('list_led/merge', data)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil Menggabungkan File!',
          icon: 'EditIcon',
          variant: 'success',
        },
      })

      const form = this.forms.filter(form => form.type === 'finish')[0]
      this.$nextTick(() => {
        const refKey = `${form.type}-${form.id}`
        const component = this.$refs[refKey][0]
        if (component) {
          component.readyForDownload(merged.filename)
          this.onUpdateStatus(this.led.has_reviewed ? 'Dapat Ditinjau Ulang' : 'Dapat Ditinjau')
        }

        this.isFinishDisabled = false
      })
    },
    onTabChange(prevTab, currentTab) {
      this.selectedTab = currentTab
      this.slideTab(currentTab)
      // CHECK IF TAB ON NEXT CLICK AND NOT FINISH
      if (prevTab < currentTab) {
        const form = this.forms[prevTab]
        if (form) {
          console.log(`prevTab (${prevTab}) < currentTab (${currentTab}) ${form}`)
          this.$nextTick(() => {
            const refKey = `${form.type}-${form.id}`
            const component = this.$refs[refKey][0]
            console.log(`my form : ${form.type}`)
            if (component) {
              component.submit()
              if (this.led.status === 'Dapat Ditinjau' || this.led.status === 'Dapat Ditinjau Ulang') {
                this.onUpdateStatus('Proses Pengisian')
              }
            }
            try {
              const formAutoCheck = this.forms[currentTab]
              const refKeyAutoCheck = `${formAutoCheck.type}-${formAutoCheck.id}`
              const componentAutoCheck = this.$refs[refKeyAutoCheck][0]
              if (formAutoCheck.type === 'finish') {
                const isCheck = componentAutoCheck.autoCheck()
                this.isFinishDisabled = !componentAutoCheck.validatorParent()
                console.log(`@autoCheck is running ${JSON.stringify(isCheck)}`)
                console.log(`@autoCheck isFinishDisabled ${!componentAutoCheck.validatorParent()} - ${this.isFinishDisabled}`)
              }
            } catch (err) {
              console.log(`@autoCheck something wrong | this function only work on finish ${err}`)
            }
          })
        }
      }
      if (prevTab > currentTab) {
        const form = this.forms[prevTab]
        if (form) {
          console.log(`prevTab (${prevTab}) > currentTab (${currentTab}) ${form}`)
          this.$nextTick(() => {
            const refKey = `${form.type}-${form.id}`
            const component = this.$refs[refKey][0]
            if (component && form.type !== 'daftar-isi') {
              try {
                console.log(`try on ${form.type}`)
                component.backStep()
              } catch (err) {
                console.log(`catching on backstep ${err}`)
              }
            }
          })
        }
      }
    },
    changeTab(index) {
      this.slideTab(index, $formWizardRef => $formWizardRef.changeTab(0, index))
    },
    slideTab(tabNumber, callback = null) {
      const $formWizardRef = this.$refs.formWizard
      const tab = $formWizardRef.$el.querySelector(`.wizard-nav-pills li:nth-child(${tabNumber + 1})`)
      if (tab) {
        const { width: tabWidth } = tab.getBoundingClientRect()
        const { width: formWidth } = $formWizardRef.$el.getBoundingClientRect()

        $formWizardRef.$el.querySelector('.wizard-nav-pills').scrollLeft = (tab.offsetLeft - (formWidth / 2)) + (tabWidth / 2)
        callback && callback($formWizardRef)
      }
    },
    toggleFormDropdown() {
      this.formDropdownVisible = !this.formDropdownVisible
    },
    onUpdateStatus(status, unReadyDownload) {
      if (status === 'led-disable') {
        this.$alert({ title: 'Klik tombol selesai (Pada Step Konfirmasi Penyelesaian), maka status akan berubah otomatis', variant: 'danger', icon: 'XIcon' })
        return
      }
      if (unReadyDownload) {
        const form = this.forms.filter(form => form.type === 'finish')[0]
        this.$nextTick(() => {
          const refKey = `${form.type}-${form.id}`
          const component = this.$refs[refKey][0]
          if (component) {
            component.unReadyForDownload()
          }
        })
      }

      this.led.status = status

      useJwt.http.put(`list_led/${this.led.id}`, {
        status: this.led.status,
        ts: this.led.ts,
      })
        .then(response => this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' }))
        .catch(() => this.$alert({ title: 'Telah terjadi kesalahan.', variant: 'danger', icon: 'XIcon' }))
    },
    handleComponentFinish(data) {
      const formIndex = this.forms.findIndex(form => form.id == data.id)
      this.forms[formIndex] = { ...data, is_finish: true }
    },
  },
}
</script>

<style lang="scss">
  #led-form {
    .form-dropdown-card {
      position: absolute !important;
      top: 50px;
      right: 0;
      height: 400px;
      width: 300px;
      z-index: 99;
    }

    .form-list {
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ck-editor__editable_inline {
      min-height: 400px;
    }
  }
</style>

<style lang="scss">
  .vue-form-wizard {
    ::-webkit-scrollbar {
      width: 10px;
      height: 7px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .wizard-nav-pills {
      scroll-behavior: smooth;
      flex-wrap: nowrap;
      padding-bottom: 1.8rem;
      overflow-x: scroll;
      overflow-y: clip;
    }

    .wizard-nav > li {
      min-width: 400px;
    }
  }
</style>
<style lang="scss" scoped>
  #led-btn-to-top {
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 99;
    opacity: 0;
    // transform: translateY(100px);
    transition: all .5s ease;

    &.show {
      opacity: 1;
      // transform: translateY(0)
    }
  }
</style>
